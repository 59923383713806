interface Prefix {
  [key: string]: string
}

export const CURRENCY_PREFIX = {
  USD: '$',
  CAD: '$',
  EUR: '€',
  AFN: '؋',
  ALL: 'ALL',
  AMD: 'AMD',
  ARS: '$',
  AUD: '$',
  AZN: 'man.',
  BAM: 'KM',
  BDT: 'Tk',
  BGN: 'BGN',
  BHD: 'BD',
  BIF: 'FBu',
  BND: '$',
  BOB: 'Bs',
  BRL: '$',
  BWP: 'BWP',
  BYN: 'Br',
  BZD: '$',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: '$',
  CNY: 'CN¥',
  COP: '$',
  CRC: '₡',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'Dkr',
  DOP: '$',
  DZD: 'DA',
  EEK: 'Ekr',
  EGP: 'EGP',
  ERN: 'Nfk',
  ETB: 'Br',
  GBP: '£',
  GEL: 'GEL',
  GHS: 'GH₵',
  GNF: 'FG',
  GTQ: 'GTQ',
  HKD: '$',
  HNL: 'HNL',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'Ikr',
  JMD: '$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'Ksh',
  KHR: 'KHR',
  KMF: 'CF',
  KRW: '₩',
  KWD: 'KD',
  KZT: 'KZT',
  LBP: 'L.L.',
  LKR: 'SLRs',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MOP: '$',
  MUR: 'MURs',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: '$',
  NGN: '₦',
  NIO: '$',
  NOK: 'Nkr',
  NPR: 'NPRs',
  NZD: '$',
  OMR: 'OMR',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: 'PKRs',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'QR',
  RON: 'RON',
  RSD: 'din.',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SR',
  SDG: 'SDG',
  SEK: 'Skr',
  SGD: '$',
  SOS: 'Ssh',
  SYP: 'SY£',
  THB: '฿',
  TND: 'DT',
  TOP: '$',
  TRY: 'TL',
  TTD: '$',
  TWD: '$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  UYU: '$',
  UZS: 'UZS',
  VEF: 'Bs.F.',
  VND: '₫',
  XAF: 'FCFA',
  XOF: 'CFA',
  YER: 'YR',
  ZAR: 'R',
  ZMK: 'ZK',
  ZWL: '$',
} as Prefix
