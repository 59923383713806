import Typography from '../../../components/Typography/Typography'

const Grafana = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <Typography>Grafana Components</Typography>
    </div>
  )
}

export default Grafana
